.App{
  width: 100vw;
  height: 100vh;
  background-color: rgb(238, 237, 235);
}


.center-flex{
  display: flex;
  justify-content: center;
  align-items: center;
}


.comingsoon{
  flex-direction: column;
  color: rgb(47, 54, 69);

}


.comingsoon div{
  font-family: "Dancing Script", cursive;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
  font-size: 4rem;
}

.comingsoon span{
  font-size: 1.8rem;
  font-family: "Amatic SC", sans-serif;
  font-weight: 700;
  /* color: rgb(230, 185, 166); */
}